import React, { useState } from "react"
import {
  Bacground,
  Expand,
  LeftChild,
  ParentContainer,
  PlusSymbol,
  RightChild,
  SubText,
  Support,
  Title,
  Ul,
} from "../../../ToolsStyles/FrequentlyAskedQuestionsStyled"
import plus from "../../../merge/MergeAssets/plus.png"
import Question from "./Question"

const FrequentlyAskedQuestions = () => {
  const [show, setShow] = useState(1)
  return (
    <ParentContainer>
      <LeftChild>
        <Support>Support</Support>
        <Title>Frequently Asked Questions</Title>
        <SubText>
          Could not find what you were looking for? <br /> Write us at&nbsp;
          <a href="mailto: support@pdfpro.com">support@pdfpro.com</a>
        </SubText>
      </LeftChild>

      <RightChild>
        <Ul>
          <Question
            setActive={setShow}
            active={show}
            question={"What is the Word to PDF converter used for?"}
            id={1}
            answer={
              "Use PDF Pro's Word to PDF converter anytime you want to convert a Word document into a PDF file."
            }
          />

          <Question
            setActive={setShow}
            active={show}
            question={"What functions does the Word to PDF converter have?"}
            id={2}
            answer={
              "The Word to PDF converter allows you to instantly convert Word to PDF directly in your browser. "
            }
          />

          <Question
            setActive={setShow}
            active={show}
            question={"Can you convert Word to PDF for free?"}
            id={3}
            answer={
              "Yes! PDF Pro’s Word to PDF converter is 100% free to use. There are no sign-ups needed to use this tool, and there are no limits on the number of times you can use this tool."
            }
          />
          <Question
            setActive={setShow}
            active={show}
            question={"How do I convert Word to PDF for free?"}
            id={4}
            answer={
              "Simply visit PDF Pro's Word to PDF converter, upload your file, press Convert, then download your new PDF."
            }
          />
        </Ul>
      </RightChild>
    </ParentContainer>
  )
}

export default FrequentlyAskedQuestions
